<template>

  <div class="">

    <div class="hero">
        <div class="content">
            <h2 class="info">Make your brand stand out!</h2>
            <div class="info">
                <div class="title">Stay connected and keep your brand&rsquo;s information updated on GrowDiaries</div>                
            </div>
        </div>
    </div>

    <br>

    <div class="bl_box">
        <div class="content">
            <div class="info">
                <h3 class="title">Want to be listed on GrowDiaries?</h3>
                <div class="sub">Now it is possible to be instantly added to GrowDiaries as a Breeder, Nutrient company or Light producer!</div>
                <ul>
                    <li class="sub"><span>Add new products</span></li>
                    <li class="sub">
                        <div><span>Update information</span></div>
                    </li>
                    <li class="sub"><span>Add images and videos</span></li>
                    <li class="sub"><span>Add a story about your company</span></li>
                </ul>
            </div>
        </div>
    </div>
    <!--  -->
    <br>
    <div class="bl_box reverse">
        <div class="content">
            <div class="drs">
                <div class="feats">
                    <h3 class="feat feat4">Got new releases?</h3>
                    <div class="feat feat4">Update your catalogue of products without waiting!</div>
                </div>
            </div>
        </div>
    </div>

    <br>

    <!-- ------- -->
    <div class="bl_box">
        <div class="content">
            <h3 class="drs">Always stay connected with your growers!</h3>
            <div class="drs">Make sure users can always get ahold of you! Connect more than one account to your Brand&rsquo;s page! Get 'Official Representative&rsquo; status for more than 1 account</div>
        </div>
    </div>
    <!--  -->
    <br>
    <div class="bl_box_link">
        <h3 class="content">Test new products</h3>
        <div class="content">Looking for growers to test new strains? Got new products that you'd like to test before release? Look no further!</div>
    </div>


  </div>

</template>

<script setup>
 

</script>

<style scoped>


h2{
    font-size: 2rem;
    font-weight: 700;
}

 
</style>